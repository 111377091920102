import { Container } from "../../components/container";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Link,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { CollectionIngestRules, getRules } from "../../api/rules";
import { LoadingView } from "../../components/loading-view";
import { RulesTable } from "./components/rules-table";

const StypedAccordionDetails = styled(AccordionDetails)`
  > *,
  > .MuiTypography-root {
    display: block;
    margin-top: 24px;
  }
`;

function CollectionRules({
  collectionRules,
}: {
  collectionRules: CollectionIngestRules;
}) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{collectionRules.name}</Typography>
      </AccordionSummary>
      <StypedAccordionDetails>
        <RulesTable key="rules-table" data={collectionRules} />
      </StypedAccordionDetails>
    </Accordion>
  );
}

export const Rules = () => {
  const { state } = useParams();
  const stateUC = state!.toUpperCase();

  const { data, isLoading } = useQuery({
    queryKey: ["rules", state],
    queryFn: () => getRules(stateUC),
  });

  return (
    <Container>
      <Stack paddingY={4} spacing={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/settings">
            Settings
          </Link>
          <Typography color="text.primary">{stateUC}</Typography>
        </Breadcrumbs>
        <Typography variant="h2">{stateUC} Transformation Rules</Typography>
        {isLoading ? (
          <LoadingView height="100vh" />
        ) : (
          data && (
            <>
              {Object.entries(data).map(([key, collectionRules]) => (
                <CollectionRules key={key} collectionRules={collectionRules} />
              ))}
            </>
          )
        )}
      </Stack>
    </Container>
  );
};
