import { CSSProperties, ReactNode } from "react";
import { Alert, Box } from "@mui/material";
import {
  GoogleMap,
  GoogleMapProps,
  useJsApiLoader,
} from "@react-google-maps/api";
import { LoadingView } from "../loading-view";

const libraries = ["drawing" as const];

export type MapProps = Pick<GoogleMapProps, "center"> & {
  children?: ReactNode;
  height?: CSSProperties["height"];
};

export const Map = ({
  center = DEFAULT_CENTER,
  children,
  height = "100%",
}: MapProps) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries,
  });

  if (loadError) {
    return (
      <Box paddingTop={3}>
        <Alert severity="error">
          Something went wrong. Please refresh to try again.
        </Alert>
      </Box>
    );
  }

  if (!isLoaded) {
    return <LoadingView height="100%" />;
  }

  return (
    <GoogleMap
      options={{ streetViewControl: false, mapTypeControl: false }}
      mapContainerStyle={{
        height,
        width: "100%",
      }}
      center={center}
      zoom={11}
    >
      {children}
    </GoogleMap>
  );
};

const DEFAULT_CENTER = {
  lat: -33.9,
  lng: 151.12,
};
