import {
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  FeedSetting,
  getFeedSettings,
  updateFeedSettings,
} from "../../../api/feedSettings";
import { LoadingView } from "../../../components/loading-view";
import { capitalize } from "lodash";
import { ButtonContainer } from "../../create-broadcast/components/shared";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

const FEED_SOURCES = {
  live: "Live",
  mock: "S3 Mock",
  other: "Other",
};

export const FeedSettingsTable = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["feed-settings"],
    queryFn: getFeedSettings,
  });
  const [feedSettings, setFeedSettings] = useState<FeedSetting[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (data) {
      setFeedSettings(data);
    }
  }, [data]);

  const save = useCallback(async () => {
    setIsSubmitting(true);
    await updateFeedSettings(feedSettings);
    await refetch();
    setIsSubmitting(false);
  }, [feedSettings, refetch]);

  if (isLoading) {
    return <LoadingView paddingTop={8} />;
  }

  const disableInteraction = `${process.env.REACT_APP_STAGE}`.includes("prod");

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#FAFAFA" }}>
              <TableCell>Jurisdiction</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Feed</TableCell>
              <TableCell>Rules</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedSettings?.map((feedSetting, idx) => (
              <TableRow key={`${feedSetting.jurisdiction}${feedSetting.type}`}>
                <TableCell>{feedSetting.jurisdiction.toUpperCase()}</TableCell>
                <TableCell>{capitalize(feedSetting.type)}</TableCell>
                <TableCell>
                  <Select
                    value={feedSetting.source}
                    onChange={(e) => {
                      const updatedFeedSetting = {
                        ...feedSetting,
                        source: e.target.value as "live" | "mock",
                      };
                      setFeedSettings([
                        ...feedSettings.slice(0, idx),
                        updatedFeedSetting,
                        ...feedSettings.slice(idx + 1),
                      ]);
                    }}
                    size="small"
                    disabled={disableInteraction}
                  >
                    {Object.entries(FEED_SOURCES).map(([feedSource, label]) => (
                      <MenuItem
                        key={feedSource}
                        value={feedSource}
                        disabled={feedSource === "other"}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    component={Link}
                    to={`/settings/rules/${feedSetting.jurisdiction}`}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonContainer>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          onClick={save}
          disabled={disableInteraction}
        >
          Save
        </LoadingButton>
      </ButtonContainer>
    </>
  );
};
