import axios from "axios";
import { getApiEndpoint } from "../utils/fallback";

export type TransformConfigPresentation = {
  type: string;
  definition: string;
};

interface IngestRulePresentationBase {
  transforms?: TransformConfigPresentation[];
  valueType: string;
}

interface JsonPathRule extends IngestRulePresentationBase {
  type: "jsonpath";
  path: string;
}

interface XPathRule extends IngestRulePresentationBase {
  type: "xpath";
  path: string;
}

interface ConstantRule extends IngestRulePresentationBase {
  type: "constant";
  constant: string | number | boolean;
}

interface JoinRule extends IngestRulePresentationBase {
  type: "join";
  separator: string;
  rules: string;
}

interface ReferenceRule extends IngestRulePresentationBase {
  type: "reference";
  definition: string;
}

interface CustomRule extends IngestRulePresentationBase {
  type: "custom";
  definition: string;
}

export type IngestRulePresentation =
  | XPathRule
  | JsonPathRule
  | ConstantRule
  | JoinRule
  | ReferenceRule
  | CustomRule;

export function isIngestRulePresentation(
  value: IngestRulePresentation | IngestRuleMap,
): value is IngestRulePresentation {
  return [
    "jsonpath",
    "xpath",
    "constant",
    "join",
    "reference",
    "custom",
  ].includes(value?.type as string);
}

export type IngestRuleMap = {
  [key: string]: IngestRulePresentation | IngestRuleMap;
};

export type CollectionIngestRules<T = Record<string, IngestRulePresentation>> =
  {
    name: string;
    website?: {
      name: string;
      url: string;
    };
    feedUrl: string;
    feedType: "xml" | "json";
    collectionMap: T;
    itemsMap: IngestRuleMap;
  };

export type IncidentCollectionIngestRules = CollectionIngestRules<{
  incidents: IngestRulePresentation;
  updated: IngestRulePresentation;
}> & {
  areaItemsMap?: IngestRuleMap;
};

export function isIncidentCollectionIngestRule(
  value: CollectionIngestRules,
): value is IncidentCollectionIngestRules {
  return (
    "incidents" in value.collectionMap &&
    "updated" in value.collectionMap &&
    "areaItemsMap" in value
  );
}

export const getRules = (
  state: string,
): Promise<Record<string, CollectionIngestRules>> =>
  axios
    .get(`${getApiEndpoint()}/admin/settings/rules/${state}`)
    .then((res) => res.data);
