import axios from "axios";
import { getApiEndpoint } from "../utils/fallback";

export type FeedSetting = {
  jurisdiction: string;
  type: "fires" | "floods" | "toban";
  source: "live" | "mock";
};

export const getFeedSettings = (): Promise<FeedSetting[]> =>
  axios.get(`${getApiEndpoint()}/admin/settings/feeds`).then((res) => res.data);

export const updateFeedSettings = (feedSettings: FeedSetting[]) =>
  axios.patch(`${getApiEndpoint()}/admin/settings/feeds`, {
    feedSettings,
  });
